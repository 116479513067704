<template>
  <section class="u-clearfix u-gradient u-section-1" id="sec-f74e">
    <div class="u-clearfix u-sheet u-valign-middle-md u-valign-middle-sm u-valign-middle-xs u-sheet-1">
      <img class="u-align-center u-image u-image-circle u-image-contain u-preserve-proportions u-image-1"
        src="../assets/logo_wolf_supporter.png" alt="" data-image-width="600" data-image-height="600">
      <h1 class="u-align-center-xs u-text u-text-body-alt-color u-text-1">Inscrição</h1>
      <p class="u-align-center u-text u-text-body-alt-color u-text-2">Se você deseja fazer parte do <span
          style="font-weight: 700;">Wolf Motorcycle Club</span>, preencha o formulário abaixo e aguarde o nosso contato
        para entrevista.
      </p>
      <div class="u-form u-grey-5 u-form-1">
        <form action="https://forms.nicepagesrv.com/v2/form/process"
          class="u-clearfix u-form-spacing-10 u-form-vertical u-inner-form" source="email" name="form"
          style="padding: 10px;">
          <div class="u-form-group u-form-name u-label-top">
            <label for="name-6816" class="u-label u-label-1">Nome completo</label>
            <input type="text" placeholder="Insira seu Nome" id="name-6816" name="name"
              class="u-input u-input-rectangle" required="">
          </div>
          <div class="u-form-email u-form-group u-label-top">
            <label for="email-6816" class="u-label u-label-2">Email</label>
            <input type="email" placeholder="Insira um endereço de email válido" id="email-6816" name="email"
              class="u-input u-input-rectangle" required="">
          </div>
          <div class="u-form-date u-form-group u-label-top u-form-group-3">
            <label for="date-7848" class="u-label u-label-3">Data de nascimento</label>
            <input type="text" readonly="readonly" placeholder="dd/mm/aaaa" id="date-7848" name="date"
              class="u-input u-input-rectangle" required="" data-date-format="mm/dd/yyyy">
          </div>
          <div class="u-form-group u-form-phone u-label-top u-form-group-4">
            <label for="phone-8dee" class="u-label u-label-4">Telefone / Whatsapp</label>
            <input type="tel"
              pattern="\+?\d{0,3}[\s\(\-]?([0-9]{2,3})[\s\)\-]?([\s\-]?)([0-9]{3})[\s\-]?([0-9]{2})[\s\-]?([0-9]{2})"
              placeholder="Insira seu telefone (ex: 71 92323-2323)" id="phone-8dee" name="phone"
              class="u-input u-input-rectangle" required="">
          </div>
          <div class="u-form-group u-form-number u-form-number-layout-number u-label-top u-form-group-5">
            <label for="number-8b80" class="u-label u-label-5">Tempo de habilitação para conduzir moto (anos)</label>
            <div class="u-input-row" data-value="0">
              <input value="0" min="0" max="100" step="1" type="number" placeholder="" id="number-8b80" name="number"
                class="u-input u-input-rectangle u-text-black">
            </div>
          </div>
          <div class="u-form-checkbox u-form-group u-label-top u-form-group-6">
            <input type="checkbox" id="checkbox-afac" name="naotemcnh" value="On" class="u-field-input">
            <label for="checkbox-afac" class="u-field-label">Não tenho habilitação para moto mas estou no processo para
              tirar</label>
          </div>
          <div class="u-form-group u-label-top u-form-group-7">
            <label for="text-346b" class="u-label u-label-6">Motocicleta</label>
            <input type="text" placeholder="Insira a marca e modelo da sua motocicleta" id="text-346b" name="text"
              class="u-input u-input-rectangle" required="required">
          </div>
          <div class="u-form-group u-form-message u-label-top">
            <label for="message-6816" class="u-label u-label-7">Porque eu quero ser um Wolf?</label>
            <textarea placeholder="Insira sua mensagem" rows="4" cols="50" id="message-6816" name="message"
              class="u-input u-input-rectangle" required=""></textarea>
          </div>
          <div class="u-form-agree u-form-group u-label-top u-form-group-9">
            <label class="u-block-0cd5-21 u-field-label u-form-control-hidden" style="font-weight: 700"></label>
            <input type="checkbox" id="agree-2f7b" name="agree" class="u-agree-checkbox u-field-input" required="">
            <label for="agree-2f7b" class="u-agree-label u-field-label">Eu autorizo o Wolf Motorcycle Club a armazenar
              os meus dados pessoais e de contato.</label>
          </div>
          <div class="u-form-agree u-form-group u-label-top u-form-group-10">
            <label class="u-block-0cd5-35 u-field-label" style="font-weight: 700"></label>
            <input type="checkbox" id="agree-ed0c" name="agree-1" class="u-agree-checkbox u-field-input" required="">
            <label for="agree-ed0c" class="u-agree-label u-field-label">Estou ciente de que o preenchimento deste
              formulário não obriga o Wolf Motorcycle Club a aceitar-me como integrante.</label>
          </div>
          <div class="u-align-center u-form-group u-form-submit u-label-top">
            <a href="#" class="u-border-none u-btn u-btn-submit u-button-style u-grey-75 u-btn-1">Enviar</a>
            <input type="submit" value="submit" class="u-form-control-hidden">
          </div>
          <div class="u-form-send-message u-form-send-success"> Obrigado! A sua mensagem foi enviada. </div>
          <div class="u-form-send-error u-form-send-message"> Não foi possível enviar a sua mensagem. Por favor, corrija
            os erros e tente novamente. </div>
          <input type="hidden" value="" name="recaptchaResponse">
          <input type="hidden" name="formServices" value="006dfc14-6cd3-ddd5-74ef-be4a7b9f8d77">
        </form>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'InscricaoForm'
}
</script>

<style scoped>
.u-section-1 {
  background-image: linear-gradient(#333333, #808080);
}

.u-section-1 .u-sheet-1 {
  min-height: 1319px;
}

.u-section-1 .u-image-1 {
  width: 143px;
  height: 143px;
  margin: 20px auto 0;
}

.u-section-1 .u-text-1 {
  width: 294px;
  margin: 0 auto;
}

.u-section-1 .u-text-2 {
  width: 683px;
  margin: 20px auto 0;
}

.u-section-1 .u-form-1 {
  height: 565px;
  box-shadow: 5px 5px 20px 0 rgba(0,0,0,0.4);
  background-image: none;
  width: 570px;
  --thumb-color: #478ac9;
  --thumb-hover-color: #77aad9;
  --track-color: #c0c0c0;
  --track-active-color: #478ac9;
  margin: 20px auto 50px;
}

.u-section-1 .u-label-1 {
  font-weight: 700;
}

.u-section-1 .u-label-2 {
  font-weight: 700;
}

.u-section-1 .u-form-group-3 {
  margin-left: 0;
}

.u-section-1 .u-label-3 {
  font-weight: 700;
}

.u-section-1 .u-form-group-4 {
  margin-left: 0;
}

.u-section-1 .u-label-4 {
  font-weight: 700;
}

.u-section-1 .u-form-group-5 {
  --progress: 0%;
}

.u-section-1 .u-label-5 {
  font-weight: 700;
}

.u-section-1 .u-form-group-6 {
  margin-left: 0;
  font-weight: normal;
}

.u-section-1 .u-form-group-7 {
  margin-left: 0;
}

.u-section-1 .u-label-6 {
  font-weight: 700;
}

.u-section-1 .u-label-7 {
  font-weight: 700;
}

.u-section-1 .u-form-group-9 {
  margin-left: 0;
}

.u-section-1 .u-form-group-10 {
  margin-left: 0;
}

.u-section-1 .u-btn-1 {
  background-image: none;
}

@media (max-width: 991px) {
  .u-section-1 .u-sheet-1 {
    min-height: 1356px;
  }

  .u-section-1 .u-image-1 {
    margin-top: 60px;
  }

  .u-section-1 .u-form-1 {
    margin-bottom: 60px;
  }
}

@media (max-width: 767px) {
  .u-section-1 .u-text-2 {
    width: 540px;
  }

  .u-section-1 .u-form-1 {
    width: 540px;
  }
}

@media (max-width: 575px) {
  .u-section-1 .u-sheet-1 {
    min-height: 1448px;
  }

  .u-section-1 .u-image-1 {
    width: 104px;
    height: 104px;
  }

  .u-section-1 .u-text-1 {
    width: auto;
    margin-left: 23px;
    margin-right: 23px;
  }

  .u-section-1 .u-text-2 {
    width: 340px;
  }

  .u-section-1 .u-form-1 {
    width: 340px;
  }
}
</style>
