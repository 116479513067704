import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore/lite';
import { getAnalytics } from "firebase/analytics";

const config = {
  apiKey: "AIzaSyC4gVBepdprO4WUGz69UsYHWjOPQ-CPFJ4",
  authDomain: "wolfmc-website.firebaseapp.com",
  databaseURL: "https://wolfmc-website.firebaseio.com",
  projectId: "wolfmc-website",
  storageBucket: "wolfmc-website.appspot.com",
  messagingSenderId: "470632099252",
  appId: "1:470632099252:web:aef720c56f714a9715d6e1",
  measurementId: "G-8PFY4WRQZ5"
};

const app = initializeApp(config);
const firebase = getFirestore(app);
export const analytics = getAnalytics(app);

export default firebase;
