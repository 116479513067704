<template>
  <section class="u-clearfix u-section-1" id="sec-3d32">
    <div class="u-clearfix u-sheet u-sheet-1">
      <h2 class="u-text u-text-default u-text-1">Fale com o Wolf MC</h2>
      <div class="u-clearfix u-expanded-width u-layout-wrap u-layout-wrap-1">
        <div class="u-layout">
          <div class="u-layout-row">
            <div class="u-container-style u-grey-50 u-layout-cell u-size-30 u-layout-cell-1">
              <div class="u-container-layout u-container-layout-1">
                <img class="u-align-center u-image u-image-default u-image-1" src="../assets/lobo_uivando.png" alt=""
                  data-image-width="391" data-image-height="357">
                <p
                  class="u-align-center-sm u-align-center-xs u-align-left-lg u-align-left-md u-align-left-xl u-text u-text-2">
                  Tem alguma dúvida sobre o clube ou gostaria de enviar uma mensagem para a gente? Utilize este
                  formulário e nós responderemos o mais breve possível.<br>
                  <br>Siga-nos nas redes sociais para ficar ligado nos próximos eventos e conhecer um pouco mais do
                  dia-a-dia do clube:&nbsp;
                </p>
                <div class="u-align-center u-social-icons u-spacing-10 u-social-icons-1">
                  <a class="u-social-url" title="Facebook" target="_blank"
                    href="https://facebook.com/wolfmotoclube"><span
                      class="u-icon u-social-facebook u-social-icon u-text-black u-icon-1"><svg class="u-svg-link"
                        preserveAspectRatio="xMidYMin slice" viewBox="0 0 112 112" style="">
                        <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#svg-69e1"></use>
                      </svg><svg class="u-svg-content" viewBox="0 0 112 112" x="0" y="0" id="svg-69e1">
                        <circle fill="currentColor" cx="56.1" cy="56.1" r="55"></circle>
                        <path fill="#FFFFFF" d="M73.5,31.6h-9.1c-1.4,0-3.6,0.8-3.6,3.9v8.5h12.6L72,58.3H60.8v40.8H43.9V58.3h-8V43.9h8v-9.2
c0-6.7,3.1-17,17-17h12.5v13.9H73.5z"></path>
                      </svg></span>
                  </a>
                  <a class="u-social-url" title="Instagram" target="_blank"
                    href="https://instagram.com/wolfmotoclube"><span
                      class="u-icon u-social-icon u-social-instagram u-text-black u-icon-2"><svg class="u-svg-link"
                        preserveAspectRatio="xMidYMin slice" viewBox="0 0 112 112" style="">
                        <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#svg-d1cf"></use>
                      </svg><svg class="u-svg-content" viewBox="0 0 112 112" x="0" y="0" id="svg-d1cf">
                        <circle fill="currentColor" cx="56.1" cy="56.1" r="55"></circle>
                        <path fill="#FFFFFF"
                          d="M55.9,38.2c-9.9,0-17.9,8-17.9,17.9C38,66,46,74,55.9,74c9.9,0,17.9-8,17.9-17.9C73.8,46.2,65.8,38.2,55.9,38.2
z M55.9,66.4c-5.7,0-10.3-4.6-10.3-10.3c-0.1-5.7,4.6-10.3,10.3-10.3c5.7,0,10.3,4.6,10.3,10.3C66.2,61.8,61.6,66.4,55.9,66.4z"></path>
                        <path fill="#FFFFFF"
                          d="M74.3,33.5c-2.3,0-4.2,1.9-4.2,4.2s1.9,4.2,4.2,4.2s4.2-1.9,4.2-4.2S76.6,33.5,74.3,33.5z">
                        </path>
                        <path fill="#FFFFFF" d="M73.1,21.3H38.6c-9.7,0-17.5,7.9-17.5,17.5v34.5c0,9.7,7.9,17.6,17.5,17.6h34.5c9.7,0,17.5-7.9,17.5-17.5V38.8
C90.6,29.1,82.7,21.3,73.1,21.3z M83,73.3c0,5.5-4.5,9.9-9.9,9.9H38.6c-5.5,0-9.9-4.5-9.9-9.9V38.8c0-5.5,4.5-9.9,9.9-9.9h34.5
c5.5,0,9.9,4.5,9.9,9.9V73.3z"></path>
                      </svg></span>
                  </a>
                </div>
              </div>
            </div>
            <div class="u-container-style u-layout-cell u-size-30 u-layout-cell-2">
              <div class="u-container-layout u-valign-middle u-container-layout-2">
                <div class="u-expanded-width u-form u-form-1">
                  <form action="https://forms.nicepagesrv.com/v2/form/process"
                    class="u-clearfix u-form-spacing-10 u-form-vertical u-inner-form" style="padding: 10px"
                    source="email" name="form">
                    <div class="u-form-group u-form-partition-factor-2 u-label-none u-form-group-1">
                      <label for="text-30b7" class="u-label">Campo de Entrada</label>
                      <input type="text" id="text-30b7" name="name-1" class="u-input u-input-rectangle"
                        placeholder="Nome">
                    </div>
                    <div class="u-form-group u-form-name u-form-partition-factor-2 u-label-none">
                      <label for="name-3b9a" class="u-label">Name</label>
                      <input type="text" placeholder="Sobrenome" id="name-3b9a" name="lastname"
                        class="u-input u-input-rectangle" required="">
                    </div>
                    <div class="u-form-email u-form-group u-label-none">
                      <label for="email-3b9a" class="u-label">Email</label>
                      <input type="email" placeholder="E-mail" id="email-3b9a" name="email"
                        class="u-input u-input-rectangle" required="">
                    </div>
                    <div class="u-form-group u-form-phone u-label-none u-form-group-4">
                      <label for="text-ccf4" class="u-label">Campo de Entrada</label>
                      <input type="tel" placeholder="Whatsapp" id="text-ccf4" name="whatsapp"
                        class="u-input u-input-rectangle" required="required">
                    </div>
                    <div class="u-form-group u-form-message u-label-none">
                      <label for="message-3b9a" class="u-label">Message</label>
                      <textarea placeholder="Escreva sua mensagem" rows="4" cols="50" id="message-3b9a" name="message"
                        class="u-input u-input-rectangle" required=""></textarea>
                    </div>
                    <div
                      class="u-align-center-xs u-align-right-lg u-align-right-md u-align-right-sm u-align-right-xl u-form-group u-form-submit">
                      <a href="#" class="u-border-none u-btn u-btn-submit u-button-style u-grey-60 u-btn-1">Enviar</a>
                      <input type="submit" value="submit" class="u-form-control-hidden">
                    </div>
                    <div class="u-form-send-message u-form-send-success"> Thank you! Your message has been sent. </div>
                    <div class="u-form-send-error u-form-send-message"> Unable to send your message. Please fix errors
                      then try again. </div>
                    <input type="hidden" value="" name="recaptchaResponse">
                    <input type="hidden" name="formServices" value="006dfc14-6cd3-ddd5-74ef-be4a7b9f8d77">
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'ContatoForm'
}
</script>

<style scoped>
.u-section-1 .u-sheet-1 {
  min-height: 585px;
}

.u-section-1 .u-text-1 {
  font-size: 3rem;
  margin: 48px auto 0;
}

.u-section-1 .u-layout-wrap-1 {
  margin-top: 16px;
  margin-bottom: 60px;
}

.u-section-1 .u-layout-cell-1 {
  min-height: 410px;
  background-image: none;
}

.u-section-1 .u-container-layout-1 {
  padding: 30px;
}

.u-section-1 .u-image-1 {
  width: 68px;
  height: 62px;
  margin: 20px auto 0;
}

.u-section-1 .u-text-2 {
  margin: 20px 0 0;
}

.u-section-1 .u-social-icons-1 {
  height: 32px;
  min-height: 16px;
  width: 74px;
  min-width: 42px;
  margin: 9px auto 0;
}

.u-section-1 .u-icon-1 {
  height: 100%;
}

.u-section-1 .u-icon-2 {
  height: 100%;
}

.u-section-1 .u-layout-cell-2 {
  min-height: 400px;
}

.u-section-1 .u-container-layout-2 {
  padding: 15px 0;
}

.u-section-1 .u-form-1 {
  margin-top: 0;
  margin-bottom: 0;
  height: 323px;
}

.u-section-1 .u-form-group-1 {
  margin-left: 0;
}

.u-section-1 .u-form-group-4 {
  margin-left: 0;
}

.u-section-1 .u-btn-1 {
  background-image: none;
}

@media (max-width: 1199px) {
  .u-section-1 .u-sheet-1 {
    min-height: 513px;
  }

  .u-section-1 .u-layout-wrap-1 {
    position: relative;
  }

  .u-section-1 .u-layout-cell-1 {
    min-height: 338px;
  }

  .u-section-1 .u-layout-cell-2 {
    min-height: 330px;
  }
}

@media (max-width: 991px) {
  .u-section-1 .u-sheet-1 {
    min-height: 276px;
  }

  .u-section-1 .u-layout-cell-1 {
    min-height: 100px;
  }

  .u-section-1 .u-layout-cell-2 {
    min-height: 100px;
  }
}

@media (max-width: 767px) {
  .u-section-1 .u-sheet-1 {
    min-height: 376px;
  }

  .u-section-1 .u-text-1 {
    font-size: 2.25rem;
  }

  .u-section-1 .u-container-layout-1 {
    padding-left: 10px;
    padding-right: 10px;
  }

  .u-section-1 .u-text-2 {
    width: auto;
  }
}

@media (max-width: 575px) {
  .u-section-1 .u-text-1 {
    font-size: 1.875rem;
  }
}
</style>
