<template>
  <section class="u-clearfix u-section-3" id="sec-4b56">
    <div class="u-clearfix u-sheet u-valign-middle u-sheet-1">
      <div class="u-clearfix u-expanded-width u-layout-wrap u-layout-wrap-1">
        <div class="u-layout">
          <div class="u-layout-row">
            <div
              class="u-container-align-center-md u-container-align-center-sm u-container-align-center-xs u-container-style u-gradient u-layout-cell u-size-60 u-layout-cell-1">
              <div
                class="u-container-layout u-valign-middle-md u-valign-middle-sm u-valign-middle-xs u-container-layout-1">
                <img class="u-image u-image-default u-preserve-proportions u-image-1" alt="" data-image-width="568"
                  data-image-height="568" src="../assets/wffw.jpg">
                <h2 class="u-align-center u-text u-text-body-alt-color u-text-default u-text-1">Quem Somos</h2>
                <p class="u-align-center u-text u-text-body-alt-color u-text-2">O <span style="font-weight: 700;">Wolf
                    Motorcycle Club</span>&nbsp;nasceu em Salvador-BA, no ano de 2014, e segue as linhas tradicionais do
                  motociclismo internacional, prezando valores como irmandade, lealdade, disciplina, família e, claro, a
                  vida sobre uma motocicleta.<br>
                  <br>
                  <span style="font-weight: 700;">Quer fazer parte dessa família?</span> Acesse o nosso formulário de
                  inscrição e aguarde o nosso contato para uma entrevista.
                </p>
                <a href="/inscricao"
                  class="u-active-white u-align-center u-border-2 u-border-active-white u-border-hover-white u-border-white u-btn u-btn-round u-button-style u-hover-white u-none u-radius-11 u-btn-1"
                  data-animation-name="" data-animation-duration="0" data-animation-delay="0"
                  data-animation-direction="">INSCREVA-SE</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'LandingQuemSomos'
}
</script>

<style scoped>
.u-section-3 .u-sheet-1 {
  min-height: 530px;
}

.u-section-3 .u-layout-wrap-1 {
  margin: 60px 0;
}

.u-section-3 .u-layout-cell-1 {
  min-height: 411px;
  background-image: linear-gradient(to right, #999999, #404040);
}

.u-section-3 .u-container-layout-1 {
  padding: 0 11px;
}

.u-section-3 .u-image-1 {
  width: 352px;
  height: 362px;
  margin: 25px auto 0 60px;
}

.u-section-3 .u-text-1 {
  margin: -356px 198px 0 auto;
}

.u-section-3 .u-text-2 {
  margin: 20px 60px 0 494px;
}

.u-section-3 .u-btn-1 {
  border-style: solid;
  text-transform: uppercase;
  font-weight: 400;
  letter-spacing: 2px;
  font-size: 1.125rem;
  margin: 20px 230px 0 auto;
  padding: 15px 45px 16px 44px;
}

@media (max-width: 1199px) {
  .u-section-3 .u-sheet-1 {
    min-height: 574px;
  }

  .u-section-3 .u-layout-wrap-1 {
    position: relative;
    margin-right: initial;
    margin-left: initial;
  }

  .u-section-3 .u-layout-cell-1 {
    min-height: 454px;
  }

  .u-section-3 .u-image-1 {
    background-position: 50% 50%;
    width: 362px;
    margin-top: 46px;
    margin-left: 46px;
  }

  .u-section-3 .u-text-1 {
    width: auto;
    margin-top: -378px;
    margin-right: 98px;
  }

  .u-section-3 .u-text-2 {
    margin-right: 10px;
    margin-left: 444px;
  }

  .u-section-3 .u-btn-1 {
    margin-top: 10px;
    margin-right: 131px;
  }
}

@media (max-width: 991px) {
  .u-section-3 .u-sheet-1 {
    min-height: 988px;
  }

  .u-section-3 .u-layout-cell-1 {
    min-height: 868px;
  }

  .u-section-3 .u-container-layout-1 {
    padding-bottom: 29px;
  }

  .u-section-3 .u-image-1 {
    height: 348px;
    width: 348px;
    margin-top: 0;
    margin-left: auto;
  }

  .u-section-3 .u-text-1 {
    margin-top: 20px;
    margin-right: auto;
  }

  .u-section-3 .u-text-2 {
    width: 354px;
    margin-left: auto;
    margin-right: auto;
  }

  .u-section-3 .u-btn-1 {
    margin-right: auto;
  }
}

@media (max-width: 767px) {
  .u-section-3 .u-sheet-1 {
    min-height: 480px;
  }

  .u-section-3 .u-layout-wrap-1 {
    position: relative;
    margin-right: initial;
    margin-left: initial;
  }

  .u-section-3 .u-layout-cell-1 {
    min-height: 821px;
  }

  .u-section-3 .u-container-layout-1 {
    padding-left: 10px;
    padding-right: 10px;
  }

  .u-section-3 .u-text-2 {
    width: auto;
    margin-right: 46px;
    margin-left: 60px;
  }
}

@media (max-width: 575px) {
  .u-section-3 .u-sheet-1 {
    min-height: 941px;
  }

  .u-section-3 .u-container-layout-1 {
    padding: 20px 6px 16px;
  }

  .u-section-3 .u-image-1 {
    height: 328px;
    width: 328px;
    margin-right: 0;
  }

  .u-section-3 .u-text-2 {
    margin-left: 24px;
    margin-right: 24px;
  }
}
</style>
