<template>
  <section class="u-clearfix u-section-2" id="sec-73d9">
    <div class="u-clearfix u-sheet u-sheet-1">
      <div class="u-clearfix u-expanded-width u-gutter-0 u-layout-wrap u-layout-wrap-1">
        <div class="u-layout" style="">
          <div class="u-layout-row" style="">
            <div class="u-container-style u-layout-cell u-left-cell u-size-30 u-size-xs-60 u-white u-layout-cell-1"
              src="">
              <div
                class="u-container-layout u-valign-bottom-md u-valign-bottom-sm u-valign-bottom-xs u-valign-middle-lg u-valign-middle-xl u-container-layout-1">
                <span class="u-black u-icon u-icon-circle u-text-white u-icon-1"><svg class="u-svg-link"
                    preserveAspectRatio="xMidYMin slice" viewBox="0 0 54.757 54.757" style="">
                    <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#svg-781d"></use>
                  </svg><svg class="u-svg-content" viewBox="0 0 54.757 54.757" x="0px" y="0px" id="svg-781d"
                    style="enable-background:new 0 0 54.757 54.757;">
                    <g>
                      <path d="M27.557,12c-3.859,0-7,3.141-7,7s3.141,7,7,7s7-3.141,7-7S31.416,12,27.557,12z M27.557,24c-2.757,0-5-2.243-5-5
		s2.243-5,5-5s5,2.243,5,5S30.314,24,27.557,24z"></path>
                      <path d="M40.94,5.617C37.318,1.995,32.502,0,27.38,0c-5.123,0-9.938,1.995-13.56,5.617c-6.703,6.702-7.536,19.312-1.804,26.952
		L27.38,54.757L42.721,32.6C48.476,24.929,47.643,12.319,40.94,5.617z M41.099,31.431L27.38,51.243L13.639,31.4
		C8.44,24.468,9.185,13.08,15.235,7.031C18.479,3.787,22.792,2,27.38,2s8.901,1.787,12.146,5.031
		C45.576,13.08,46.321,24.468,41.099,31.431z"></path>
                    </g>
                  </svg>


                </span>
                <h2 class="u-align-center u-text u-text-1">Covil</h2>
                <p class="u-align-justify u-text u-text-2"> Após 3 anos na primeira sede do&nbsp;Wolf Moto Clube,
                  conhecida como Sede Vale dos Lagos, em 2019 inauguramos a sede atual, o nosso&nbsp;Covil.<br>
                  <br>Localizada na praia de Ipitanga, nossa sede oferece a infraestrutura completa para os integrantes
                  e visitantes com segurança, estacionamento privativo, sinuca, o bom rock 'n roll e a venda de comidas
                  gostosas e bebidas geladas.
                </p>
                <a href="https://maps.google.com/?cid=17908625570205510810" target="_blank"
                  class="u-active-none u-border-none u-btn u-button-style u-hover-none u-none u-btn-1">Rua Walter da
                  Silva Maia, 442 - Ipitanga<br>Lauro de Freitas - BA
                </a>
              </div>
            </div>
            <div
              class="u-align-center u-container-style u-image u-layout-cell u-right-cell u-size-30 u-size-xs-60 u-image-1"
              src="" data-image-width="750" data-image-height="750">
              <div class="u-container-layout u-valign-middle u-container-layout-2" src=""></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'LandingCovil'
}
</script>

<style scoped>
.u-section-2 {
  min-height: 318px;
}

.u-section-2 .u-sheet-1 {
  min-height: 655px;
}

.u-section-2 .u-layout-wrap-1 {
  margin-top: 60px;
  margin-bottom: 0;
}

.u-section-2 .u-layout-cell-1 {
  min-height: 595px;
}

.u-section-2 .u-container-layout-1 {
  padding: 0 60px 30px;
}

.u-section-2 .u-icon-1 {
  height: 48px;
  width: 48px;
  background-image: none;
  margin: 0 auto;
  padding: 10px;
}

.u-section-2 .u-text-1 {
  margin: 20px 0 0;
}

.u-section-2 .u-text-2 {
  margin: 20px 0 0;
}

.u-section-2 .u-btn-1 {
  text-transform: uppercase;
  font-size: 0.875rem;
  letter-spacing: 1px;
  margin: 30px auto 0;
  padding: 0;
}

.u-section-2 .u-image-1 {
  min-height: 595px;
  background-image: url("../assets/sede.jpg");
  background-position: 50% 50%;
}

.u-section-2 .u-container-layout-2 {
  padding: 30px 60px;
}

@media (max-width: 1199px) {
  .u-section-2 .u-sheet-1 {
    min-height: 203px;
  }

  .u-section-2 .u-layout-cell-1 {
    min-height: 491px;
  }

  .u-section-2 .u-btn-1 {
    border-style: none none solid;
  }

  .u-section-2 .u-image-1 {
    min-height: 491px;
  }
}

@media (max-width: 991px) {
   .u-section-2 {
    min-height: 66px;
  }

  .u-section-2 .u-sheet-1 {
    min-height: 77px;
  }

  .u-section-2 .u-layout-cell-1 {
    min-height: 636px;
  }

  .u-section-2 .u-container-layout-1 {
    padding-left: 0;
    padding-right: 0;
  }

  .u-section-2 .u-text-1 {
    margin-left: 30px;
    margin-right: 30px;
  }

  .u-section-2 .u-text-2 {
    margin-left: 30px;
    margin-right: 30px;
  }

  .u-section-2 .u-image-1 {
    min-height: 376px;
  }

  .u-section-2 .u-container-layout-2 {
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media (max-width: 767px) {
  .u-section-2 .u-layout-cell-1 {
    min-height: 100px;
  }

  .u-section-2 .u-container-layout-1 {
    padding-left: 50px;
    padding-right: 50px;
  }

  .u-section-2 .u-image-1 {
    min-height: 564px;
  }

  .u-section-2 .u-container-layout-2 {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (max-width: 575px) {
  .u-section-2 .u-layout-cell-1 {
    min-height: 541px;
  }

  .u-section-2 .u-container-layout-1 {
    padding-left: 0;
    padding-right: 0;
  }

  .u-section-2 .u-text-1 {
    margin-right: 50px;
    margin-left: 38px;
  }

  .u-section-2 .u-text-2 {
    width: auto;
    margin-left: 0;
    margin-right: 0;
  }

  .u-section-2 .u-image-1 {
    min-height: 355px;
  }
}
</style>
