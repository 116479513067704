<template>
  <section class="skrollable skrollable-between u-align-center u-clearfix u-image u-shading u-section-1"
    id="carousel_ad8d" src="" data-image-width="1795" data-image-height="800">
    <div class="u-clearfix u-sheet u-valign-middle-sm u-valign-middle-xs u-sheet-1">
      <div class="u-clearfix u-expanded-width u-layout-wrap u-layout-wrap-1">
        <div class="u-layout">
          <div class="u-layout-row">
            <div
              class="u-container-style u-layout-cell u-size-19-md u-size-19-sm u-size-19-xs u-size-30-lg u-size-30-xl u-layout-cell-1">
              <div class="u-container-layout u-container-layout-1"></div>
            </div>
            <div
              class="u-container-align-center-sm u-container-style u-layout-cell u-size-30-lg u-size-30-xl u-size-41-md u-size-41-sm u-size-41-xs u-layout-cell-2">
              <div class="u-container-layout u-valign-middle-sm u-container-layout-2">
                <h2 class="u-align-center u-text u-text-body-alt-color u-text-default-lg u-text-default-xl u-text-1"
                  data-animation-name="customAnimationIn" data-animation-duration="1500">Wolf Motorcycle Club</h2>
                <p class="u-align-center u-large-text u-text u-text-body-alt-color u-text-variant u-text-2"
                  data-animation-name="customAnimationIn" data-animation-duration="1500" data-animation-delay="250">Quer
                  fazer parte da nossa família?</p>
                <a href="/inscricao"
                  class="u-active-white u-align-center u-border-2 u-border-active-white u-border-hover-white u-border-white u-btn u-btn-round u-button-style u-hover-white u-none u-radius-11 u-btn-1"
                  data-animation-name="" data-animation-duration="0" data-animation-delay="0"
                  data-animation-direction="">INSCREVA-SE</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'LandingHero'
}
</script>

<style scoped>
.u-section-1 {
  background-image: linear-gradient(0deg, rgba(0,0,0,0.45), rgba(0,0,0,0.45)), url("../assets/wolfmc-wallpaper-site.png");
  background-position: 50% 50%;
}

.u-section-1 .u-sheet-1 {
  min-height: 636px;
}

.u-section-1 .u-layout-wrap-1 {
  margin-top: -2px;
  margin-bottom: 2px;
}

.u-section-1 .u-layout-cell-1 {
  min-height: 636px;
}

.u-section-1 .u-container-layout-1 {
  padding: 30px;
}

.u-section-1 .u-layout-cell-2 {
  min-height: 636px;
}

.u-section-1 .u-container-layout-2 {
  padding: 30px 0;
}

.u-section-1 .u-text-1 {
  --animation-custom_in-translate_x: 0px;
  --animation-custom_in-translate_y: 0px;
  --animation-custom_in-opacity: 0;
  --animation-custom_in-rotate: 0deg;
  --animation-custom_in-scale: 0.3;
  text-shadow: 2px 2px 8px rgba(0,0,0,0.4);
  width: 455px;
  font-size: 3rem;
  margin: 162px auto 0;
}

.u-section-1 .u-text-2 {
  --animation-custom_in-translate_x: 0px;
  --animation-custom_in-translate_y: 0px;
  --animation-custom_in-opacity: 0;
  --animation-custom_in-rotate: 0deg;
  --animation-custom_in-scale: 0.3;
  text-shadow: 2px 2px 8px rgba(0,0,0,0.4);
  margin: 50px 30px 0;
}

.u-section-1 .u-btn-1 {
  border-style: solid;
  text-transform: uppercase;
  font-weight: 400;
  letter-spacing: 2px;
  font-size: 1.125rem;
  margin: 50px auto 0;
  padding: 15px 45px 16px 44px;
}

@media (max-width: 1199px) {
  .u-section-1 .u-sheet-1 {
    min-height: 412px;
  }

  .u-section-1 .u-layout-cell-1 {
    min-height: 524px;
  }

  .u-section-1 .u-layout-cell-2 {
    min-height: 524px;
  }

  .u-section-1 .u-text-2 {
    margin-left: 0;
    margin-right: 0;
  }
}

@media (max-width: 991px) {
  .u-section-1 .u-sheet-1 {
    min-height: 289px;
  }

  .u-section-1 .u-layout-cell-1 {
    min-height: 254px;
  }

  .u-section-1 .u-layout-cell-2 {
    min-height: 100px;
  }

  .u-section-1 .u-text-1 {
    width: auto;
    font-size: 2.25rem;
    margin-top: 113px;
    margin-right: 61px;
    margin-left: 40px;
  }
}

@media (max-width: 767px) {
  .u-section-1 .u-sheet-1 {
    min-height: 552px;
  }

  .u-section-1 .u-layout-wrap-1 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .u-section-1 .u-layout-cell-1 {
    min-height: 60px;
  }

  .u-section-1 .u-container-layout-1 {
    padding-left: 10px;
    padding-right: 10px;
  }

  .u-section-1 .u-layout-cell-2 {
    min-height: 492px;
  }

  .u-section-1 .u-text-1 {
    margin-top: 0;
    margin-left: 51px;
    margin-right: 51px;
  }

  .u-section-1 .u-text-2 {
    width: auto;
  }
}

@media (max-width: 575px) {
  .u-section-1 .u-sheet-1 {
    min-height: 367px;
  }

  .u-section-1 .u-layout-cell-1 {
    min-height: 34px;
  }

  .u-section-1 .u-layout-cell-2 {
    min-height: 332px;
  }

  .u-section-1 .u-text-1 {
    font-size: 1.875rem;
    margin-top: 30px;
    margin-left: -57px;
    margin-right: -57px;
  }

  .u-section-1 .u-text-2 {
    margin-top: 20px;
  }

  .u-section-1 .u-btn-1 {
    margin-top: 20px;
  }
}
</style>
