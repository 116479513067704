<template>
  <header
    class="u-black u-border-2 u-border-custom-color-1 u-border-no-left u-border-no-right u-border-no-top u-box-shadow u-clearfix u-header u-header"
    id="sec-79f4">
    <div class="u-clearfix u-sheet u-sheet-1">
      <img class="u-image u-image-contain u-image-default u-preserve-proportions u-image-1" src="../assets/wolfmc.png"
        alt="" data-image-width="213" data-image-height="254">
      <nav class="u-menu u-menu-dropdown u-offcanvas u-menu-1">
        <div class="menu-collapse" style="font-size: 1rem; letter-spacing: 0px;">
          <a class="u-button-style u-custom-left-right-menu-spacing u-custom-padding-bottom u-custom-text-active-color u-custom-top-bottom-menu-spacing u-nav-link u-text-active-palette-1-base u-text-hover-palette-2-base"
            href="#">
            <svg class="u-svg-link" viewBox="0 0 24 24">
              <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#menu-hamburger"></use>
            </svg>
            <svg class="u-svg-content" version="1.1" id="menu-hamburger" viewBox="0 0 16 16" x="0px" y="0px"
              xmlns:xlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg">
              <g>
                <rect y="1" width="16" height="2"></rect>
                <rect y="7" width="16" height="2"></rect>
                <rect y="13" width="16" height="2"></rect>
              </g>
            </svg>
          </a>
        </div>
        <div class="u-nav-container">
          <ul class="u-nav u-unstyled u-nav-1">
            <li class="u-nav-item"><a
                class="u-button-style u-nav-link u-text-active-grey-40 u-text-hover-palette-2-base"
                href="/" style="padding: 10px 20px;">Página Inicial</a>
            </li>
            <li class="u-nav-item"><a
                class="u-button-style u-nav-link u-text-active-grey-40 u-text-hover-palette-2-base"
                href="/inscricao" style="padding: 10px 20px;">Inscrição</a>
            </li>
            <li class="u-nav-item"><a
                class="u-button-style u-nav-link u-text-active-grey-40 u-text-hover-palette-2-base" href="/contato"
                style="padding: 10px 20px;">Contato</a>
            </li>
          </ul>
        </div>
        <div class="u-nav-container-collapse">
          <div class="u-black u-container-style u-inner-container-layout u-opacity u-opacity-95 u-sidenav">
            <div class="u-inner-container-layout u-sidenav-overflow">
              <div class="u-menu-close"></div>
              <ul class="u-align-center u-nav u-popupmenu-items u-unstyled u-nav-2">
                <li class="u-nav-item"><a class="u-button-style u-nav-link" href="/">Página
                    Inicial</a>
                </li>
                <li class="u-nav-item"><a class="u-button-style u-nav-link" href="/inscricao">Inscrição</a>
                </li>
                <li class="u-nav-item"><a class="u-button-style u-nav-link" href="/contato">Contato</a>
                </li>
              </ul>
            </div>
          </div>
          <div class="u-black u-menu-overlay u-opacity u-opacity-70"></div>
        </div>
      </nav>
      <h4 class="u-text u-text-default u-text-1">Wolf Motorcycle Club</h4>
    </div>
  </header>
</template>

<script>
export default {
  name: 'MenuPrincipal'
}
</script>
