<template>
  <b-container fluid="lg" id="cargo">
    <b-row>
      <h1>Cargos</h1>
    </b-row>
    <b-row>
      <div v-for="cargo in lista" :key="cargo.descricao">
        <input v-model="cargo.descricao" type="text">
      </div>
    </b-row>
  </b-container>
  <!--div id="cargo">
    <b-card text-variant="dark">
      <b-row>
        <b-col cols="auto" class="mr-auto"><h4 class="card-title">Cargos</h4></b-col>
        <b-col cols="auto"><b-button size="sm" v-b-toggle.collapse-cargos>{{ textobotao }}</b-button></b-col>
      </b-row>
      <b-collapse id="collapse-cargos">
        <b-button-toolbar justify>
          <b-form inline v-if="showForm">
            <b-input size="sm" class="mb-2 mr-sm-2 mb-sm-2" id="descricao"
              v-model="form.descricao" placeholder="Descrição" />
            <b-input size="sm" class="mb-2 mr-sm-2 mb-sm-2" id="ordem"
              v-model="form.ordem" placeholder="Ordem" />
            <b-form-checkbox class="mb-2 mr-sm-2 mb-sm-2" id="diretoria"
              v-model="form.diretoria">
              Diretoria
            </b-form-checkbox>
            <b-button size="sm" class="mb-2 mr-sm-2 mb-sm-2"
              variant="primary" @click="salvar"
              v-b-tooltip.hover title="Salvar">
              <font-awesome-icon :icon="{ prefix: 'fas', iconName: 'check' }" />
            </b-button>
            <b-button size="sm" class="mb-2 mr-sm-2 mb-sm-2"
              @click="cancelar" variant="danger"
              v-b-tooltip.hover title="Cancelar">
              <font-awesome-icon :icon="{ prefix: 'fas', iconName: 'xmark' }" />
            </b-button>
          </b-form>
          <b-button size="sm" class="mb-2 mr-sm-2 mb-sm-2"
            @click="showForm = !showForm" variant="primary"
            v-b-tooltip.hover title="Novo" v-if="!showForm">
            <font-awesome-icon icon="plus" />
          </b-button>
        </b-button-toolbar>
        <b-row>
          <b-col>
            <b-table hover striped :fields="fields" :items="cargos"
              sort-by="ordem" :sort-desc="false"
              show-empty empty-text="Nenhum item encontrado">
              <template #cell(diretoria)="data">
                <span class="check" v-if="data.value"><font-awesome-icon :icon="{ prefix: 'fas', iconName: 'check' }" /></span>
                <span class="xmark" v-else><font-awesome-icon :icon="{ prefix: 'fas', iconName: 'xmark' }" /></span>
              </template>
              <template #cell(actions)="data">
                <b-button variant="primary" size="sm"
                  v-b-tooltip.hover title="Alterar"
                  @click="editar(data)"
                  class="mr-2">
                  <font-awesome-icon :icon="{ prefix: 'fas', iconName: 'pencil' }" />
                </b-button>
                <b-button variant="danger" size="sm"
                  v-b-tooltip.hover title="Excluir"
                  @click="remover(data)"
                  class="mr-2">
                  <font-awesome-icon :icon="{ prefix: 'fas', iconName: 'xmark' }" />
                </b-button>
              </template>
            </b-table>
          </b-col>
        </b-row>
      </b-collapse>
    </b-card>
  </div-->
</template>

<script>
import { onUnmounted, ref } from 'vue';
import firebase from '../../config/firebase'
import { onSnapshot, collection, orderBy, query } from 'firebase/firestore/lite';

export default {
  name: 'AdmCargo',
  data () {
    return {
      showForm: false,
      key: '',
      fields: [
        { key: 'descricao', label: 'Descrição' },
        { key: 'ordem', label: 'Ordem' },
        { key: 'diretoria', label: 'Diretoria' },
        { key: 'actions', label: 'Ações' }
      ],
      lista: ref([]),
      form: {
        descricao: '',
        ordem: '',
        diretoria: false
      },
      proximo: null,
      anterior: null,
      textobotao: 'abrir'
    }
  },
  mounted () {
    this.$root.$on('bv::collapse::state', (collapseId, isJustShown) => {
      if (collapseId === 'collapse-cargos') {
        this.textobotao = isJustShown ? 'fechar' : 'abrir'
      }
    })

    const dbquery = query(collection(firebase, 'cargos'), orderBy('ordem'));
    const consulta = onSnapshot (dbquery, (snapshot) => {
      this.lista = snapshot.docs.map((doc) => {
        return {
          id: doc.id,
          descricao: doc.data().descricao,
          ordem: doc.data().ordem
        }
      })
    })
    onUnmounted(consulta);
  },
  methods: {
    /*...mapActions([
      'createLog'
    ]),*/
    salvar: function () {
      if (this.key === '') {
        this.$firebaseRefs.cargos.push(this.form).then(() => { this.$toasted.success('Cargo cadastrado com sucesso!') })
        //this.createLog({acao: 'cargo_novo', dados: this.form})
      } else {
        this.$firebaseRefs.cargos.child(this.key).set(this.form).then(() => { this.$toasted.success('Cargo alterado com sucesso!') })
        //this.createLog({acao: 'cargo_alterado', dados: this.form})
      }
      this.key = ''
      this.form.descricao = ''
      this.form.ordem = ''
      this.form.diretoria = false
      this.showForm = !this.showForm
    },
    editar: function (item) {
      this.key = item['.key']
      this.form.descricao = item['descricao']
      this.form.ordem = item['ordem']
      this.form.diretoria = item['diretoria']
      this.showForm = true
    },
    remover: function (item) {
      if (confirm('Deseja remover o cargo ' + item['descricao'] + '?')) {
        this.$firebaseRefs.cargos.child(item['.key']).remove().then(() => { this.$toasted.success('Cargo removido com sucesso!') })
        this.createLog({acao: 'cargo_removido', dados: item})
      }
    },
    cancelar: function () {
      this.key = ''
      this.form.descricao = ''
      this.form.diretoria = false
      this.showForm = !this.showForm
    }
  }
}
</script>
