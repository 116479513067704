<template>
  <Menu/>
  <ContatoForm/>
  <Rodape/>
</template>

<script>
import Menu from './Menu.vue'
import ContatoForm from './ContatoForm.vue'
import Rodape from './Rodape.vue'

export default {
  name: 'ContatoPage',
  components: {
    Menu,
    ContatoForm,
    Rodape
  }
}
</script>

<style>

</style>
