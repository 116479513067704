import { createRouter, createWebHistory } from 'vue-router'

import LandingPage from '../components/Landing'
import InscricaoPage from '../components/Inscricao'
import ContatoPage from '../components/Contato'
import AdmCargo from '../components/adm/Cargo'

const routes = [
  {
    path: '/',
    name: 'LandingPage',
    component: LandingPage
  },
  {
    path: '/inscricao',
    name: 'InscricaoPage',
    component: InscricaoPage
  },
  {
    path: '/contato',
    name: 'ContatoPage',
    component: ContatoPage
  },
  {
    path: '/adm/cargo',
    name: 'AdmCargo',
    component: AdmCargo
  },
  { path: '/:pathMatch(.*)*', redirect: '/' }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
