<template>
  <Menu/>
  <InscricaoForm/>
  <Rodape/>
</template>

<script>
import Menu from './Menu.vue'
import InscricaoForm from './InscricaoForm.vue'
import Rodape from './Rodape.vue'

export default {
  name: 'InscricaoPage',
  components: { 
    Menu,
    InscricaoForm,
    Rodape
  }
}
</script>

<style>

</style>
