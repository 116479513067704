<template>
  <Menu/>
  <LandingHero/>
  <LandingCovil/>
  <LandingQuemSomos/>
  <Rodape/>
</template>

<script>
import Menu from './Menu.vue'
import LandingHero from './LandingHero.vue'
import LandingCovil from './LandingCovil.vue'
import LandingQuemSomos from './LandingQuemSomos.vue'
import Rodape from './Rodape.vue'

export default {
  name: 'LandingPage',
  components: {
    Menu,
    LandingHero,
    LandingCovil,
    LandingQuemSomos,
    Rodape
  }
}
</script>

<style>

</style>
